import React, { useState, useEffect, useRef } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { useNavigate } from "react-router-dom";
import { Modal, Spin, Tooltip } from "antd";

import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { CollectionsPage2 } from "../modal/scheduled/create_training";
import { baseurl } from "../../lib/settings";
import LoadingModal from "../modal/loading";
import {
  GenericSuccessMessage,
  SomeThingErrorMessage,
} from "../Alerts/genericAlert";
import "./calendar.css"
import { LoadingOutlined } from "@ant-design/icons";
import { DescriptionSpinLoading } from "../Spin/custom_spin";

const localizer = momentLocalizer(moment);

export default function Calendare({sugettionTrainingBarChartControl}) {
  const [visible, setVisible] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [isloadingOnStart, setIsloadingOnStart] = useState(false);
  const [isfinish, setIsfinish] = useState(false);
  const [startDate, setStartDate] = useState();
  const [training, setTraining] = useState();
  const [control, setControl] = useState();
  const [events, setEvents] = useState([]);
  const [color, setColor] = useState("#aabbcc");
  const [message, setMessage] = useState("Training scheduled sucessfuly");
  const loadIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;
  const navigate = useNavigate();

  useEffect(() => {
    setIsloadingOnStart(true);
    baseurl.get("v1/get_training/", {params : {range_of_day:'all',request_type:'calendare'}}).then((resp) => {
      setEvents(resp.data);
      setIsloadingOnStart(false);
    }).catch((err) => {
      setIsloadingOnStart(false);
      console.log("Error on geting treaning calendare", err);
    });
    setControl(false);
  }, [control]);

  // const getEndDateFormatedCalendar = (dateString) =>{
  //   var date = new Date(dateString)
  //   date.setDate(date.getDate() + 1);
  //   return date
  // };

  const myEventsList = events.map((item) => ({
    start: item.start_date,
    end: item.end_date,
    title: item.formation.description,
    id: item.id,
    color: item.formation.color,
    capacity: item.capacity,
    employee: item.employee.length,
    start_time:item.start_time,
    end_time:item.end_time,
  }));
  

  

  async function sendData(value) {
    setIsloading(true);
    setStartDate(moment(value.date[0]._d).format("YYYY-MM-DD"));
    const data = {
      formation: training,
      capacity: parseInt(value.capacity),
      start_date: moment(value.date[0].$d).format("YYYY-MM-DD"),
      end_date: moment(value.date[1].$d).format("YYYY-MM-DD"),
      start_time: value.start_time,
      end_time: value.end_time,
      color: color,
    };
    baseurl
      .post("v1/booking/training/", data)
      .then((resp) => {
        setControl(true);
        setIsloading(false);
        setIsfinish(true);
        GenericSuccessMessage(message);
        sugettionTrainingBarChartControl(true)
      })
      .catch((err) => {
        setIsloading(false);
        SomeThingErrorMessage("Something didn't go as expected. Try again");
      });
  }

  const onSelectEvent = (event) => {
    navigate("/addemployee", { state: { item: event } });
  };

  const controlDateShedule = (startDate) => {
    var today_date = new Date()
    today_date = moment(today_date).format('YYYY-MM-DD')
    var chose_date = moment(startDate).format('YYYY-MM-DD')
    if (today_date !== chose_date && today_date > chose_date) {
      Modal.error({
        title: 'Action not permitted',
        content: 'It is not possible to schedule training for a later date.',
      });
    }else{
      setVisible(true); 
    }
  }

  return (
    <>
      <div className="App">
        {isloadingOnStart ? <DescriptionSpinLoading description={''} size={30} /> : <>
        <Calendar
          localizer={localizer}
          events={myEventsList}
          // startAccessor="start"
          // endAccessor="end"
          style={{ height: 500}}
          tooltipAccessor={(event) => event.title +" [ "+ moment(event.start_time, "HH:mm:ss").format("hh:mm A") + " - " + moment(event.end_time, "HH:mm:ss").format("hh:mm A") + " ]"}
          // date={new Date()} 
          onSelectEvent={(event) => onSelectEvent(event)}
          onSelectSlot={(slotInfo) => {
            controlDateShedule(slotInfo.start)
          }}
          
          // onSelecting={(event) => console.log("Show more", event)}
          selectable
          views={['month', 'week', 'day']} 
          eventPropGetter={(event) => {
            const backgroundColor = event.color;
            return { style: { backgroundColor } }
            
          }}
          // tooltipAccessor="MAC"
          popup={true}
          
        /> 
        
        </>}
        
        {visible && (
          <CollectionsPage2
            onChange={(item) => {
              console.log("Content", item);
              sendData(item);
              setVisible(false);
            }}
            onCancel={(item) => {
              setVisible(item);
            }}
            date={(item) => {
              console.log("Date In Calendare ", item);
            }}
            trainingID={(item) => {
              setTraining(item);
            }}
            loading={isloading}
          />
        )}
      </div>
    </>
  );
}
