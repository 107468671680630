
export const visitorsColumns = [
    {
      title: "Visitor",
      dataIndex: "name",
      key: "name",
      width: "32%",
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
    },
    {
      title: "Registration date",
      key: "r_date",
      dataIndex: "r_date",
    },
    {
      title: "Delete",
      key: "action",
      dataIndex: "action",
    },
    {
      title: "Resend",
      key: "resend",
      dataIndex: "resend",
    },
  ];

  export const userAccountsColumns = [
    {
      title: "Users",
      dataIndex: "name",
      key: "name",
      width: "32%",
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
    },
    {
      title: "Permission",
      dataIndex: "permission",
      key: "permission",
    },
    {
      title: "Last seen",
      key: "last_seen",
      dataIndex: "last_seen",
    },
    // {
    //   title: "Status",
    //   key: "status",
    //   dataIndex: "status",
    // },
    
   
  ];